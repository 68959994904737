<template>
  <div class="parent card-body mx-auto col-12">
    <datepicker :bootstrap-styling="true"
                :calendar-button="false"
                :disabled="disable"
                :format="datePickerFormat"
                :language="ja"
                :typeable="false"
                :value="date"
                @closed="doSelected"
                @input="date = fixDate($event)"
                class="calendar"
                id="calendar"
                placeholder="yyyy/mm/ddで入力"
                ref="picker"></datepicker>
    <div class="calendar-icon-area"
         v-on:click="doOpen">
      <button class="calendar-icon"/>
    </div>
  </div>
</template>

<script>
    import Datepicker from "vuejs-datepicker";
    import {ja} from 'vuejs-datepicker/dist/locale'

    export default {
        name: 'Calender',
        components: {Datepicker},
        props: {
            disable: {
                type: Boolean,
                default: false
            },
            currentDate: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: ""
            },
        },
        data() {
            return {
                datePickerFormat: "yyyy/MM/dd",
                ja: ja,
                date: this.currentDate,
                result: this.disable
            }
        },
        watch: {
            disable(newValue) {
                this.result = newValue;
                let calenderCount = document.getElementsByClassName('calendar-icon').length;
                if (this.type === 'vaccination') {
                    const icon = document.getElementsByClassName('calendar-icon')[0];
                    if (newValue == 1) {
                        icon.setAttribute("disabled", true);
                    } else {
                        icon.removeAttribute('disabled');
                    }
                } else if (this.type === 'pcr' && calenderCount === 2) {
                    const icon = document.getElementsByClassName('calendar-icon')[1];
                    if (newValue == 1) {
                        icon.setAttribute("disabled", true);
                    } else {
                        icon.removeAttribute('disabled');
                    }
                } else if (this.type === 'pcr' && calenderCount === 1) {
                    const icon = document.getElementsByClassName('calendar-icon')[0];
                    if (newValue == 1) {
                        icon.setAttribute("disabled", true);
                    } else {
                        icon.removeAttribute('disabled');
                    }
                }
            },
            currentDate(newValue) {
                this.date = newValue;
            },
        },
        mounted() {
            const icon = document.getElementsByClassName('calendar-icon');
            for (let i = 0; i < icon.length; i++) {
                if (this.result == 1) {
                    icon[i].setAttribute("disabled", true);
                } else {
                    icon[i].removeAttribute('disabled');
                }
            }
        },
        methods: {
            doOpen: function () {
                if (!this.$refs.picker.isOpen) {
                    this.$refs.picker.$el.querySelector("input").focus();
                    this.$refs.picker.showCalendar();
                }
            },
            doClose: function () {
                if (this.$refs.picker.isOpen) {
                    this.$refs.picker.close(true);
                }
            },
            doSelected: function () {
                // Remove focus after selecting a date
                this.$refs.picker.$el.querySelector("input").blur();

                this.$emit('update', this.date)
            },
            fixDate(event) {
                return moment(event).format('YYYY-MM-DD');
            },
        }
    };
</script>

<style scoped>
  .parent {
    position: relative;
    margin-bottom: 20px;
  }

  .calendar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .calendar-icon-area {
    position: absolute;
    top: 1px;
    left: calc(100% - 49px);
    width: 48px;
    height: 46px;
    background-color: #FFFFFF;
    border-radius: 0 2px 2px 0;
  }

  .calendar-icon {
    position: absolute;
    top: 12px;
    left: calc(100% - 36px);
    width: 24px;
    height: 24px;
    background: url("../../assets/images/ic_calendar@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: auto;
    border: none;
    outline: none;
  }

  .calendar-icon:disabled {
    background: url("../../assets/images/ic_calendar_disable@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>
